
// vue
import { createApp } from "petite-vue";
//fancyapps
import "@fancyapps/ui/dist/fancybox.esm.js";
import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;
import jqueryZoom from  "jquery-zoom"
jqueryZoom($)

import "@fancyapps/ui/dist/fancybox.css";
// bootstrap icons
import "bootstrap-icons/font/bootstrap-icons.css";
// smoothscroll
import SmoothScroll from "smoothscroll-for-websites";
// utilities
import { getElementTop } from "./utilities";
// countUp
import initCountUp from "./countUp";
// gsap
import initGSAP from "./gsap";
// swiper
import initSwiper from "./swiper";
// tailwindcss
import "../css/input.css";


$(function () {
    var initZoom = function () {
  $('.zoom').zoom({ touch: false })
}
  initZoom()

})


// Check if element is in viewport
function isElementInViewport(el, inCB, outCB, rootMargin) {
  var margin = rootMargin || '-10%';
  function handleIntersect(entries, observer) {
    var entry = entries[0];
    if (entry.isIntersecting) {
      if (inCB && typeof inCB === 'function') inCB(el, entry);
    } else {
      if (outCB && typeof outCB === 'function') outCB(el, entry);
    }
  }
  var observer = new IntersectionObserver(handleIntersect, { rootMargin: margin });
  el ? observer.observe(el) : ''
}


// fangao中的js

createApp({
  doc_toggle: false,
  fileName: null,
  sub_menu_toggle: false,
  pro_de_nav_index: 0,
  about_nav_index: 0,
  about_active_index: 0,
  pro_de_nav: [
    "BasicInformation",
    "ProductDescription",
    "SizeInformation",
    "DetailImage"
  ],
  about_nav: [
    "AboutProfoundGarment",
    "CorporateCulture",
    "OurTeam",
    "Milestone",
    "Factory"
  ],
  quote_toggle: false,
  // 以上是fangao
  // data
  loading_toggle: true,
  sidebar_toggle: false,
  contact_toggle: false,
  search_toggle: false,
  video_toggle: false,
  video_src: "",
  mobile_menu_toggle: false,
  aside_menu_toggle: false,
  to_posi: Function,
  zoomIn: Function,
  home_tab_index: 0,
  get_home_tab_index: 0,
  faq_nav_tab_index:0,//因为i从0开始
  premier_swiper_fn: Function,
  // methods
  doc_toggle_fn(val) {
    if (val) {
      document.querySelector("body").classList.add("doc--active");
    } else {
      document.querySelector("body").classList.remove("doc--active");
    }
  },
  aside_menu_toggle_fn(e, toggle) {
    // this.routerState = e.srcElement.dataset.cur;
    console.log('e',e,'toggle',toggle)
    this.aside_menu_toggle = !this.aside_menu_toggle;
  },
  mobile_menu_toggle_fn(e, toggle) {
    this.doc_toggle_fn(toggle);
    this.mobile_menu_toggle = toggle;
    if (e) {
      let x = e.clientX;
      let y = e.clientY;
      this.zoomIn("#mobile-menu", x, y, { xPercent: -50, yPercent: -50 });
    }
  },
  parent_active_toggle(e) {
    e.currentTarget.parentElement.classList.toggle("active");
  },
  self_active_toggle(e) {
    e.currentTarget.classList.toggle("active");
  },
  contact_toggle_fn(e, toggle) {
    this.doc_toggle_fn(toggle);
    this.contact_toggle = toggle;
    if (e) {
      let x = e.clientX;
      let y = e.clientY;
      this.zoomIn("#pop-form", x, y);
    }
  },
  search_toggle_fn(e, toggle) {
    this.doc_toggle_fn(toggle);
    this.search_toggle = toggle;
    if (e) {
      let x = e.clientX;
      let y = e.clientY;
      this.zoomIn("#pop-search", x, y);
    }
  },
  video_toggle_fn(e, toggle) {
    if (toggle) {
      this.video_src = e.currentTarget.dataset.src;
    }
    this.doc_toggle_fn(toggle);
    this.video_toggle = toggle;
    if (e) {
      let x = e.clientX;
      let y = e.clientY;
      this.zoomIn("#pop-video", x, y);
    }
  },
  get_about_active_index_fn(i) {
    this.about_active_index = i
  },
  get_home_tab_index_fn(i) {
    // var premierSwiper = document.querySelector('.premier-swiper').swiper
    this.get_home_tab_index = i
    this.premier_swiper_fn.slideTo(i)
    this.premier_swiper_fn.autoplay.stop()
  },
  fileUpLoadChange() {
    if (this.$refs.file.files[0]) {
      this.fileName = this.$refs.file.files[0].name
    }
  },
  scroll_nav(title, src) {
    let ref_title = title.replace(/\s*/g, "")
    let scroll_top = this.$refs[ref_title].offsetTop - 120
    if (src) {
      scroll_top = this.$refs[ref_title].offsetTop - 220
    }
    window.scroll({
      top: scroll_top
    })
  },
  // mobile_menu_toggle_fn(toggle) {
  //   this.doc_toggle = toggle
  //   this.mobile_menu_toggle = toggle
  // },
  mobile_menu_arrow_fn(e) {
    e.currentTarget.parentElement.classList.toggle('open')
  },
  // mounted
  mounted() {
    this.loading_toggle = false;
    // init swiper
    let swiper_obj = initSwiper();
    this.premier_swiper_fn = swiper_obj.premier_swiper;
    this.premier_swiper_fn.on('slideChange', (e) => {
      this.get_home_tab_index = e.activeIndex
    })
    // initCountUp
    initCountUp();
    // initGSAP
    let gsap_obj = initGSAP();
    this.to_posi = gsap_obj.scrollTo;
    this.zoomIn = gsap_obj.zoomIn;
    // SmoothScroll
    SmoothScroll({ animationTime: 600 });
    // console.info
    console.info("Design by HQT huaqiutong.com");

    // fangao的scroll
    let ticking = false
    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // do something...
          window.scrollY > 200 ? this.sidebar_toggle = true : this.sidebar_toggle = false;
          this.pro_de_nav.forEach((e, i) => {
            if (this.$refs[e]) {
              if (this.$refs[e].getBoundingClientRect().top <= 300) {
                this.pro_de_nav_index = i
              }
            }
          });
          this.about_nav.forEach((e, i) => {
            if (this.$refs[e]) {
              if (this.$refs[e].getBoundingClientRect().top <= 300) {
                this.about_nav_index = i
              }
            }
          })
          if (this.$refs.GetAQuote) {
            if (this.$refs.GetAQuote.getBoundingClientRect().top <= 0) {
              this.quote_toggle = true
            } else {
              this.quote_toggle = false
            }
          }
          ticking = false;
        });
        ticking = true;
      }
    })
  }
}).mount("#app");
